import React, { Suspense, useEffect, useRef, useState } from 'react';
import { AppShell, Navbar, Header, Group, useMantineColorScheme, UnstyledButton, ThemeIcon, Text, createStyles, Burger, Box, useMantineTheme, Skeleton } from '@mantine/core';
// import { MainLinks } from './_mainLinks';
import { User } from './_user';
import { Logo } from './_logo';
import { MainLinks, menuData } from './_mainLinks';
import { AltHistory } from '../../utils/history';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Routes, Route } from 'react-router-dom';

//import Locations from '../../modules/Locations/Locations';

import { ModalsProvider } from '@mantine/modals';
import { IconSettings } from '@tabler/icons';
import SettingsModule from 'modules/Settings/SettingsModule';
import GuidesModule from 'modules/Guides/GuidesModule';
import SeasonsModule from 'modules/Seasons/SeasonsModule';
import { getProp } from 'utils/helper';
import { useDispatch } from 'react-redux';
import { setUser } from 'modules/Auth/authSlice';
import { logger } from 'utils/logger';
import Style from 'modules/Styles/features/style/Style';
import { setPreviousRoute } from 'app/appSlice';
import { useLocalStorage } from '@mantine/hooks';
import { SpotlightControl } from 'core/components/index';

//import Sessions from 'modules/Sessions/SessionsModule';

// MODULES
const TalentsModule = React.lazy(() => import('../../modules/Talent/TalentModule'));
const LocationsModule = React.lazy(() => import('../../modules/Locations/Locations'));
const WarehouseModule = React.lazy(() => import('../../modules/Warehouse/Warehouse'));
const SessionModule = React.lazy(() => import('modules/Sessions/SessionModule'));

const useStyles = createStyles((theme, _params, getRef) => ({
    '.nav-open': {
        [`.${getRef('logoBg')}`]: {
            maxWidth: 0,
        },
    },
    container: {
        height: '100%',
    },
    mainPanel: {
        height: '100%',
        alignItems: 'start',
        width: '100%',
        position: 'relative',
        // paddingRight:theme.spacing.lg,
        // paddingLeft:200 ,
    },
    menuItem: {
        display: 'block',
        width: '100%',
        borderRadius: theme.radius.sm,
        // width: '100%',
        flexGrow: 1,
        padding: theme.spacing.xs,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[9],
        border: 'none',
        '&:hover': {
            backgroundColor:
                // theme.colorScheme === 'dark' ? theme.colorScheme : theme.colors.gray[0],
                theme.colorScheme === 'dark' ? theme.colorScheme : '#F5F7EE',
            '.themeIcon': {
                color: theme.colors.brand[1],
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.brand[4] : theme.colors.brand[4],

                svg: {
                    transform: 'scale(1.1)',
                },
            },
        },
        '.themeIcon': {
            color: theme.colorScheme === 'dark' ? theme.colors.brand[5] : theme.colors.brand[8],
            transition: 'all .2s ease-in',
        },
        '&.active': {
            color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
            '.themeIcon': {
                color: theme.colors.brand[1],
                backgroundColor: theme.colors.brand[6],
            },
        },
    },
    moduleLoader: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },
    subMenuWrapper: {
        // backgroundColor: `${theme.colorScheme}`,
        backgroundColor: `${theme.colors.grape[9]}`,
        paddingTop: theme.spacing.sm,
        height: '100%',
        width: 220,
        borderRight: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.gray[2]}`,
        borderLeft: `1px solid ${theme.colors.gray[2]}`,
        marginTop: '0',
    },
    navWrapper: {
        borderRight: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[5]}` : `1px solid ${theme.colors.gray[2]}`,
        '#nav-wrapper': {
            width: 220,
            transition: 'width .1s ease-out',
        },
    },
    navbar: {
        overflow: 'hidden',
        border: 'none',
        backgroundColor: '#151D1E',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#ffffff',
        // borderRight: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[2]}` : `1px solid ${theme.colors.gray[2]}`,
        position: 'relative',
        top: 0,
        paddingLeft: `9px !important`,
    },
    navClosed: {
        '#nav-wrapper': {
            width: 68,
        },
    },
    logoBg: {
        ref: getRef('logoBg'),
        borderRight: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.colors.gray[3]}`,
    },
    burgerContainer: {
        width: 68,
        height: 59,
        justifyContent: 'center',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#ffffff',
    },
   
}));

function Shell({ user }) {
    const { colorScheme } = useMantineColorScheme();
    const history = AltHistory(useNavigate());
    const location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState(menuData[5]);
    const [navOpen, toggleNav] = useLocalStorage({ key: 'oi-navOpen', defaultValue: true });
    const { classes } = useStyles(colorScheme, navOpen);
    const theme = useMantineTheme();
    const shellRef = useRef(null);

    const dispatch = useDispatch();

    // set the user details if we have them in local storage
    useEffect(() => {
        if (!user) {
            const userStr = getProp('oi-user');
            if (userStr) {
                logger.log('SETTING USER*******');
                const tmpUser = JSON.parse(userStr);
                dispatch(setUser(tmpUser));
            }
        }
    }, [user, dispatch]);

    const onMenuClick = (item) => {
        logger.log('menu clicked', item);
        setSelectedMenu(item);

        const tmpLoc = `${location.pathname}/${location.search}`;
        dispatch(setPreviousRoute(tmpLoc));

        history.push(item.route, true);
    };
    // const onSubMenuClick = (item) => {
    //   logger.log(item);
    //   history.push(item.route)
    // }

    useEffect(() => {
        let menuItem = menuData.find((item) => location.pathname.indexOf(item.route) > -1);
        if (menuItem && menuItem.route !== selectedMenu?.route) {
            setSelectedMenu(menuItem);
        }
    }, [location.pathname, selectedMenu]);

    let logoBg = 'transparent';

    if (colorScheme === 'dark') {
        //logoBg = theme.colors.dark[7];
        //if(navOpen){
        logoBg = theme.colors.dark[7];
        //}
    } else {
        //if(navOpen){
        // logoBg = '#151D1E';
        //}
    }

    const mouseOverHandler = (e) => {
        if (!navOpen) {
            logger.log(e);

            if (e.type === 'mouseenter') {
                shellRef.current.classList.add('nav-hover');
            } else {
                shellRef.current.classList.remove('nav-hover');
            }
        }
    };

    return (
        <ModalsProvider>
            {/* <AppShell className={cx(classes.navWrapper, { [classes.navClosed]: navOpen === false })} */}
            <AppShell
                className={navOpen ? 'nav-open' : ''}
                ref={shellRef}
                padding='md'
                navbar={
                    <div id='nav-wrapper' className={classes.navWrapper} onMouseEnter={(e) => mouseOverHandler(e)} onMouseLeave={(e) => mouseOverHandler(e)}>
                        <Navbar className={classes.navbar} width={{ base: 220 }} height={'100%'} p='xs'>
                            <Navbar.Section grow>
                                <MainLinks callBack={onMenuClick} selectedMenu={selectedMenu} />
                            </Navbar.Section>
                            <Navbar.Section>
                                {/* <User /> */}
                                <UnstyledButton
                                    sx={{ paddingLeft: 10 }}
                                    className={selectedMenu && selectedMenu.route === '/settings' ? `${classes.menuItem} active` : `${classes.menuItem}`}
                                    onClick={() => onMenuClick({ route: '/settings' })}>
                                    <Group spacing={17}>
                                        <ThemeIcon
                                            size='lg'
                                            radius={'xl'}
                                            className='themeIcon'
                                            style={{ border: 'none' }}
                                            variant='filled'
                                            sx={(theme) => ({
                                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.brand[9] : theme.colors.brand[2],
                                            })}>
                                            <IconSettings />
                                        </ThemeIcon>
                                        {/* <ThemeIcon color={'black'} size="lg" variant="light">
                  <Settings/>
                </ThemeIcon> */}

                                        <Text color={theme.colorScheme === 'dark' ? 'white' : theme.colors.brand[8]} size='sm'>
                                            Settings
                                        </Text>
                                    </Group>
                                </UnstyledButton>
                            </Navbar.Section>
                        </Navbar>
                    </div>
                }
                header={
                    <Header id='app-header' height={60}>
                        <Group sx={{ height: '100%' }} pr={20} position='left' grow>
                            <Group sx={{ maxWidth: 220, position: 'relative' }}>
                                <Group className={classes.burgerContainer}>
                                    <Burger color={theme.colorScheme === 'dark' ? 'white' : 'black'} opened={navOpen} onClick={() => toggleNav(!navOpen)} title={'Open Nav'} />
                                </Group>
                                {/* <Button onClick={()=>toggleNav(!navOpen)}>Toggle</Button> */}
                                <Box className={`${classes.logoBg} logoBg`} sx={{ backgroundColor: logoBg }}></Box>
                                <div className='app-header-menu-logo'>
                                    <Logo colorScheme={colorScheme} />
                                </div>
                            </Group>
                            <Group position='apart' sx={{ maxWidth: '100%' }}>
                                <SpotlightControl />
                                <Group>
                                    {/* <Button variant="outline" size='sm' onClick={() => navigate('./approval')}>Approval</Button> */}
                                    {user ? <User data={user} /> : null}
                                    {/* <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
                  {colorScheme === 'dark' ? <IconSun size={16} /> : <IconMoonStars size={16} />}
                </ActionIcon> */}
                                </Group>
                            </Group>
                        </Group>
                    </Header>
                }
                styles={(theme) => ({
                    main: {
                        padding: 0,
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                        // borderLeft: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[4]}` : `1px solid ${theme.white}`,
                        zIndex: 1,
                        height: `calc(100vh - 60px)`,
                        minHeight: '100%',
                    },
                })}>
                <Group className={classes.container} spacing='xs' style={{ gap: 0 }}>
                    {/* <Box id='sub-menu-wrapper' className={classes.subMenuWrapper} 
        sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.brand[0],   
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],   
          }
        )}
        >
          <Title mt='xs' mb='md' px="lg" order={4}>{selectedMenu.label}</Title>
          <div>
          {selectedMenu.items?selectedMenu.items.map((link) => {
            let active = location.pathname.indexOf(link.route) > -1?true:false;

            return <SubMenuLink {...link} key={link.label} active={active} onClick={(val) => onSubMenuClick(val)} />
          }):null}
          </div>
        </Box> */}

                    <div id='content-container' className={classes.mainPanel}>
                        <Routes>
                            <Route
                                path='/warehouse/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <WarehouseModule />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='/styleguides/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <GuidesModule />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='/talent/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <TalentsModule />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='/locations/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <LocationsModule />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='/sessions/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <SessionModule />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='/seasons/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <SeasonsModule />
                                    </Suspense>
                                }
                            />
                            <Route
                                path='/settings/*'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <SettingsModule />
                                    </Suspense>
                                }
                            />

                            <Route
                                path='/styles/:id'
                                element={
                                    <Suspense fallback={<ModulePreloader />}>
                                        <Style />
                                    </Suspense>
                                }
                            />

                            <Route path='/' element={<Navigate replace to='/seasons/dashboard' />} />
                        </Routes>
                    </div>
                </Group>
            </AppShell>
        </ModalsProvider>
    );
}

// function SubMenuLink({ icon, color, label, route, items, onClick, active }) {
//   const { classes } = useStyles();
//   const buttonClick = () => {
//       onClick({label,route})
//   }
//   const cls = active?'active':'';

//   return (
//   <UnstyledButton
//     onClick={buttonClick}
//     className={[classes.menuItem,cls]}
//     sx={(theme) => ({
//       borderRadius:0,
//       paddingLeft:theme.spacing.lg,
//       transition:'all .2s',
//       '&.active':{
//         backgroundColor:theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.brand[1],
//         borderLeft:`4px solid ${theme.colors.brand[6]}`,
//         borderColor: theme.colorScheme === 'dark' ? theme.colors.brand[6] : theme.colors.brand[9],

//       }
//     })}
//   >
//     <Group>
//       <Text size="sm">{label}</Text>
//     </Group>
//   </UnstyledButton>
//   );
// }

const ModulePreloader = () => {
    const { classes } = useStyles();
    return (
        <div className={classes.moduleLoader}>
            <Box px='lg'>
                <Group
                    className='main-container-header'
                    position='apart'
                    sx={(theme) => ({
                        borderBottom: `1px solid ${theme.colors.gray[3]}`,
                        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                    })}>
                    {/* <Box sx={{paddingTop: 20, paddingBottom: 20}}> */}
                    <Skeleton height={15} width={200} mt={20} mb={20} radius='xl' />
                    {/* </Box> */}
                    {/* <Crumbline></Crumbline> */}
                    {/* <Button onClick={() => onCreateClick()}>Create Session</Button> */}
                </Group>

                <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} py='xs' mt='sm'></Group>
            </Box>
        </div>
    );
};
export default Shell;
