import { Badge, Button, Card, CloseButton, createStyles, Group, Image, Kbd, Text } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
      width:300,
      position:'relative',
      paddingTop:'0 !important',
    },
  
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      lineHeight: 1,
    },
  }));

const image = 'assets/images/tips/001.jpg';

const ApplicationTip = ({onClose}) => {

    const {classes} = useStyles();
    const onTipClose = () => {
        onClose();
    }
    
    return (
        <Group sx={{position:'absolute', bottom:15, zIndex:99, left:15, right:15,alignContent:'flex-end', justifyContent:'end'}}>

            <Card withBorder className={classes.card}>
                <CloseButton sx={{position:'absolute', right:5, top:5, zIndex:9}} onClick={() => onTipClose()} />
                <Card.Section>
                    <Image src={image} alt={'title'} height={100} />
                </Card.Section>

                <Group position="left" mt="xl">
                    <Badge size='xs' variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} >Hot Tip</Badge>
                    <Text size="sm" weight={700} className={classes.title}>
                     Adding Annotations
                    </Text>
                    <Group spacing={5}>
                    {/* <Text size="xs" color="dimmed">
                        80% completed
                    </Text> */}
                    
                    </Group>
                </Group>
                <Text mt="sm" mb="md" color="dimmed" size="xs">
                    When in selection mode Hold <Kbd>shift</Kbd> to draw an annotation quickly
                </Text>

                <Button variant="default" fullWidth onClick={() => onTipClose()}>Ok got it!</Button>
                {/* <Card.Section className={classes.footer}>section here</Card.Section> */}
                </Card>


        {/* <Notification sx={{opacity:.8}} icon={<IconInfoCircle size={18} />} color="green" onClose={() => onTipClose()}>
            
        </Notification> */}
        </Group>
    )
}

export default ApplicationTip;


export const TipTypes = {
    APPROVAL:'oi-approvalTips',
  }