import { createSlice } from '@reduxjs/toolkit'
import { authUser, forgotPassword, initUser } from 'services/authService';
import { cacheProp, clearToken } from 'utils/helper';
// import { authUser, forgotPassword, initUser } from 'services/authService';
import { logger } from "utils/logger";

const authSlice = createSlice({
  name: 'auth',
  initialState: {user:null,success:null,loading:false,error:null},
  reducers: {
    setUser(state, action) {
      //const todo = state.find(todo => todo.id === action.payload)
      const user = action.payload;
      state.user = {...user};
    },
    authUserStart(state,action) {
      state.user = null;
      state.success = null;
      state.loading = true;
    },
    // authUser(state,action){

    // },
    authUserSuccess(state, action) {
      logger.log('authUserSuccess : SUCCESS', action)
      
      
      state.loading = false;
      state.success = {type:action.type,message:'User Auth Success'};
      state.user = {...action.payload};

      cacheProp('oi-user',JSON.stringify(state.user));

      // create the user token
      // if(action.payload.urls){
      //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}  
      // }
      
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null
    },
    authUserFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = {type:action.type,message:'Incorrect Username/Password'}
    },

    
    //////////////////////
    // LOGOUT USER
    //////////////////////
    logoutUserStart(state,action) {
      state.user = null;
      state.success = null;
      state.loading = true;
    },
    // authUser(state,action){

    // },
    logoutUserSuccess(state, action) {
      logger.log(action.type,' : SUCCESS', action)
      
      state.loading = false;
      state.success = {type:action.type,message:'Logout Auth Success'};
      state.user = null;
      state.error = null;
      // clear the token
      clearToken();
      const cb = action.payload.redirect && action.payload.redirect.length?`?redirect=${encodeURIComponent(action.payload.redirect)}`:'' 
      window.location.href = `${process.env.REACT_APP_HOST_URL}/auth/login${cb}`
    },
    logoutUserFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = {type:action.type,message:'Incorrect Username/Password'}
    },
    //////////////////////
    // INIT USER
    //////////////////////
    initUserStart(state,action) {
      state.user = null;
      state.success = null;
      state.loading = true;
    },

    initUserSuccess(state, action) {
      state.loading = false;
      state.success = {type:action.type,message:'User Auth Success'}
      state.error = null
    },
    initUserFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = action.payload
    },

    //////////////////////
    // FORGOT PWORD
    //////////////////////
    forgotPWStart(state,action) {
      state.user = null;
      state.success = null;
      state.loading = true;
    },

    forgotPWSuccess(state, action) {
      state.loading = false;
      state.success = {type:action.type,message:'Password reminder sent Success'}
      state.error = null
    },
    forgotPWFailure(state, action) {
      logger.log(state,action,'fail')
      state.error = action.payload
    },

    //////////////////////
    // CLEANUP
    ////////////////////// 
    cleanRequests(state,action){
      state.success = null;
      state.error = null;
    }
  }
})

export const logoutUser = ( user,redirect ) => async dispatch => {
  try {
    logger.log(user)
    dispatch(logoutUserStart())
    // const validUser = await authUser(user.username,user.password)
    dispatch(logoutUserSuccess({success:true,redirect:redirect}))
  } catch (err) {
    logger.log('err',err)
    dispatch(logoutUserFailure(err))
  }
}

export const loginUser = ( user ) => async dispatch => {
  try {
    logger.log(user)
    dispatch(authUserStart())
    const validUser = await authUser(user.username,user.password)
    dispatch(authUserSuccess(validUser))
  } catch (err) {
    logger.log('err',err)
    dispatch(authUserFailure(err))
  }
}

export const initialiseUser = ( setupPhrase, user ) => async dispatch => {
  try {
    dispatch(initUserStart())
    const validUser = await initUser(user.password, setupPhrase);
    dispatch(initUserSuccess(true))    
  } catch (err) {
    logger.log('err',err)
    dispatch(initUserFailure(err))
  }
}

export const forgotPW = ( email ) => async dispatch => {
  try {

    dispatch(forgotPWStart())
    const validUser = await forgotPassword(email)
    dispatch(forgotPWSuccess(true))
  } catch (err) {
    logger.log('err',err)
    dispatch(forgotPWFailure(err))
  }
}



export const { setUser
, authUserStart
, authUserSuccess
, authUserFailure 

, logoutUserStart
, logoutUserSuccess
, logoutUserFailure

, initUserStart
, initUserSuccess
, initUserFailure

, forgotPWStart
, forgotPWSuccess
, forgotPWFailure

, cleanRequests

} = authSlice.actions

export default authSlice.reducer
